<template>
    <div class="vip-content">
        <div class="vip-title">
            <img src="@/assets/img/i-tit2.png" alt="">
			{{ NotifyDetail.categoryName }}
        </div>
        <div class="vip-content-inner">
           <div class="message-content">
               <div class="title">
                   <h3>{{ NotifyDetail.title }}</h3>
                       <p>发送人:{{ NotifyDetail.createUserName || '管理员' }} 发送时间: {{ NotifyDetail.createTime }}</p>
               </div>
               <div class="content">
                   <div v-html=" NotifyDetail.content "></div>
               </div>
           </div>
        </div>
    </div>
</template>

<script>
import {noticeDetail,messageRecordDetail} from "@/api/account";
import Bus from "@/utils/bus";

export default {
    name: "detail",
	data(){
		return{
			current:1,
			size:10,
			total:0,
			NotifyDetail:'',

		}
	},
	mounted(){
		console.log(this.$route.query) // 获取传递参数
		this.getnoticeDetail(this.$route.query.id)
	},
	methods:{


		//  -------------------------接口请求
		getnoticeDetail(id){ // 获取信息详情
			// let userId=JSON.parse(localStorage.getItem('userInfo')).userId
			console.log(id)
            messageRecordDetail({id:id}).then((res) => {
				if (res.data.code==200) {
					this.NotifyDetail=res.data.data
					this.NotifyDetail.content=this.$utils.showHtml(res.data.data.content)
					// this.NotifyList=res.data.data.records
					// this.total=Number(res.data.data.total)
                    Bus.$emit('refreshMessage', '刷新消息')
				}

			})
		},

	},
}
</script>

<style scoped>

</style>
